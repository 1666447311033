<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <template>
          <div class="orderdetail-wrapper">
            <h3 class="mb-4">Order ID#{{ order.order_no }}</h3>
            <section class="shadow-box mb-3">
              <div class="wizard-wrapper">
                <div class="wizard-inner">
                  <div class="wizard-box wizard-box-filled">
                    <i class="fas fa-calendar-alt"></i>
                  </div>
                  <p class="mb-0 pt-3">Order Created</p>
                </div>
                <div class="wizard-inner">
                  <div :class="filledClass.payment">
                    <i class="fas fa-cash-register"></i>
                  </div>
                  <p class="mb-0 pt-3">Payment Success</p>
                </div>
                <div v-if="delivery" class="wizard-inner">
                  <div :class="filledClass.delivery">
                    <i class="fas fa-truck"></i>
                  </div>
                  <p class="mb-0 pt-3">On Delivery</p>
                </div>
                <div class="wizard-inner">
                  <div :class="filledClass.delivered">
                    <i class="fas fa-hand-holding-heart"></i>
                  </div>
                  <p class="mb-0 pt-3">Order Delivered</p>
                  <span class="pt-2 d-inline-block"></span>
                </div>
                <div class="wizard-inner">
                  <div :class="filledClass.review">
                    <i class="fas fa-hand-holding-heart"></i>
                  </div>
                  <p class="mb-0 pt-3">Give Review</p>
                  <span class="pt-2 d-inline-block"></span>
                </div>
              </div>
            </section>
            <section class="map-wrapper mb-3">
              <!--              <div class="map-wrapper-first-sec">-->
              <!--&lt;!&ndash;                <div class="shadow-box mb-3">&ndash;&gt;-->
              <!--&lt;!&ndash;                  <iframe :src="map_src"></iframe>&ndash;&gt;-->
              <!--&lt;!&ndash;                </div>&ndash;&gt;-->
              <!--&lt;!&ndash;                <div class="delivery-guy-wrapper ">&ndash;&gt;-->
              <!--&lt;!&ndash;                  <div class="delivery-guy-first-section shadow-box">&ndash;&gt;-->
              <!--&lt;!&ndash;                    <div class="d-flex justify-content-start">&ndash;&gt;-->
              <!--&lt;!&ndash;                      <img :src="'loyal-img.png'" alt="delivery guy" />&ndash;&gt;-->
              <!--&lt;!&ndash;                      <div class="ml-4">&ndash;&gt;-->
              <!--&lt;!&ndash;                        <span class="text-grey d-inline-block pb-1"&ndash;&gt;-->
              <!--&lt;!&ndash;                          >Delivery guy</span&ndash;&gt;-->
              <!--&lt;!&ndash;                        >&ndash;&gt;-->
              <!--&lt;!&ndash;                        <h4>Louis Simatupang</h4>&ndash;&gt;-->
              <!--&lt;!&ndash;                        <span class="text-orange">ID 412455</span>&ndash;&gt;-->
              <!--&lt;!&ndash;                      </div>&ndash;&gt;-->
              <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
              <!--&lt;!&ndash;                    <div class="phone-number d-flex align-items-center">&ndash;&gt;-->
              <!--&lt;!&ndash;                      <p>&ndash;&gt;-->
              <!--&lt;!&ndash;                        <a href="javascript:void(0)"&ndash;&gt;-->
              <!--&lt;!&ndash;                          ><i class="fas fa-comment-dots"></i&ndash;&gt;-->
              <!--&lt;!&ndash;                        ></a>&ndash;&gt;-->
              <!--&lt;!&ndash;                      </p>&ndash;&gt;-->
              <!--&lt;!&ndash;                      <p class="ml-3">&ndash;&gt;-->
              <!--&lt;!&ndash;                        <a href="javascript:void(0)"&ndash;&gt;-->
              <!--&lt;!&ndash;                          ><i class="fas fa-phone-alt"></i&ndash;&gt;-->
              <!--&lt;!&ndash;                        ></a>&ndash;&gt;-->
              <!--&lt;!&ndash;                      </p>&ndash;&gt;-->
              <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
              <!--&lt;!&ndash;                  </div>&ndash;&gt;-->
              <!--&lt;!&ndash;                  <div&ndash;&gt;-->
              <!--&lt;!&ndash;                    class="delivery-guy-second-section shadow-box d-flex justify-content-start"&ndash;&gt;-->
              <!--&lt;!&ndash;                  >&ndash;&gt;-->
              <!--&lt;!&ndash;                    <img :src="'airplane 1.png'" alt="airplane" />&ndash;&gt;-->
              <!--&lt;!&ndash;                    <div class="ml-4">&ndash;&gt;-->
              <!--&lt;!&ndash;                      <span class="text-grey d-inline-block pb-1"&ndash;&gt;-->
              <!--&lt;!&ndash;                        >Estimated Time</span&ndash;&gt;-->
              <!--&lt;!&ndash;                      >&ndash;&gt;-->
              <!--&lt;!&ndash;                      <p class="mb-0 font-weight-bold">10-14 Min</p>&ndash;&gt;-->
              <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
              <!--&lt;!&ndash;                  </div>&ndash;&gt;-->
              <!--&lt;!&ndash;                </div>&ndash;&gt;-->
              <!--              </div>-->
              <div class="map-wrapper-first-sec">
                <div class="shadow-box table-responsive p-0">
                  <table class="w-100 table">
                    <thead class="px-3">
                      <tr>
                        <th>Items</th>
                        <th>Qty</th>
                        <th>Price</th>
                        <th>Total Price</th>
                      </tr>
                    </thead>
                    <tbody class="p-3">
                      <tr
                        class="mb-3"
                        v-for="item in detail.items"
                        :key="item.uuid"
                      >
                        <td>
                          <div class="table-img-tr">
                            <img
                              :src="item.menu_item.src"
                              @error="checkImage"
                              class="item-img mb-sm-2"
                              alt="item image"
                            />
                            <div class="ml-3">
                              <span class="text-orange">{{
                                item.menu_item.item_name
                              }}</span>
                              <p class="font-weight-bold">
                                {{ item.menu_item.description }}
                              </p>
                              <div>
                                <i class="fas fa-star text-yellow"></i>
                                <i class="fas fa-star text-yellow"></i>
                                <i class="fas fa-star text-yellow"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>{{ item.quantity }}x</td>
                        <td>£{{ item.menu_item.item_price }}</td>
                        <td>
                          £{{
                            Number(
                              (
                                item.quantity * item.menu_item.item_price
                              ).toFixed(1)
                            )
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="map-wrapper-second-sec">
                <div class="shadow-box">
                  <div class="user-detail-section text-center">
                    <img
                      v-if="detail.user.avatar_src === null"
                      :src="'loyal-img.png'"
                      class="mb-2"
                      alt="user image"
                    />
                    <img
                      v-else
                      :src="detail.user.avatar_src"
                      class="mb-2"
                      alt="user image"
                    />
                    <p class="font-weight-bold">{{ detail.user.name }}</p>
                    <button class="btn custom-btn-style cursor-move">
                      Customer
                    </button>
                  </div>
                  <div class="user-detail-contact mt-4">
                    <ul class="phone-number mb-3 p-0">
                      <li class="mb-3">
                        <p class="mb-0"><i class="fas fa-phone-alt"></i></p>
                        <a
                          href="tel:+51 5125 626 77"
                          class="font-weight-bold ml-3"
                        >
                          {{ detail.user.mobile_number }}
                        </a>
                      </li>
                      <li>
                        <p class="mb-0">
                          <i class="fas fa-map-marker-alt"></i>
                        </p>
                        <a
                          href="javascript:void(0)"
                          class="font-weight-bold ml-3"
                          >{{ detail.user.profile.location.address_1 }},
                          {{ detail.user.profile.location.city }},
                          {{ detail.user.profile.location.city }}</a
                        >
                      </li>
                    </ul>
                    <!--                    <div class="mt-5 note-order">-->
                    <!--                      <p class="font-weight-bold">Note Order</p>-->
                    <!--                      <span class="text-grey">-->
                    <!--                      </span>-->
                    <!--                    </div>-->
                  </div>
                </div>
              </div>
            </section>
            <!--            <section class="map-wrapper ">-->
            <!--              <div class="map-wrapper-first-sec">-->
            <!--                <div class="shadow-box table-responsive p-0">-->
            <!--                  <table class="w-100 table">-->
            <!--                    <thead class="px-3">-->
            <!--                      <tr>-->
            <!--                        <th>Items</th>-->
            <!--                        <th>Qty</th>-->
            <!--                        <th>Price</th>-->
            <!--                        <th>Total Price</th>-->
            <!--                      </tr>-->
            <!--                    </thead>-->
            <!--                    <tbody class="p-3">-->
            <!--                      <tr class="mb-3">-->
            <!--                        <td class="d-flex justify-content-start">-->
            <!--                          <img :src="'loyal-img.png'" />-->
            <!--                          <div class="ml-3">-->
            <!--                            <span class="text-orange">MAIN COURSE</span>-->
            <!--                            <p class="font-weight-bold">-->
            <!--                              Chicken curry special with cucumber-->
            <!--                            </p>-->
            <!--                            <div>-->
            <!--                              <i class="fas fa-star text-yellow"></i>-->
            <!--                              <i class="fas fa-star text-yellow"></i>-->
            <!--                              <i class="fas fa-star text-yellow"></i>-->
            <!--                              <i class="fas fa-star"></i>-->
            <!--                              <i class="fas fa-star"></i>-->
            <!--                            </div>-->
            <!--                          </div>-->
            <!--                        </td>-->
            <!--                        <td>3x</td>-->
            <!--                        <td>$14.99</td>-->
            <!--                        <td>$44.97</td>-->
            <!--                        <td><i class="fas fa-times-circle text-danger"></i></td>-->
            <!--                      </tr>-->
            <!--                      <tr class="mb-3">-->
            <!--                        <td class="d-flex justify-content-start">-->
            <!--                          <img :src="'loyal-img.png'" />-->
            <!--                          <div class="ml-3">-->
            <!--                            <span class="text-orange">MAIN COURSE</span>-->
            <!--                            <p class="font-weight-bold">-->
            <!--                              Chicken curry special with cucumber-->
            <!--                            </p>-->
            <!--                            <div>-->
            <!--                              <i class="fas fa-star text-yellow"></i>-->
            <!--                              <i class="fas fa-star text-yellow"></i>-->
            <!--                              <i class="fas fa-star text-yellow"></i>-->
            <!--                              <i class="fas fa-star"></i>-->
            <!--                              <i class="fas fa-star"></i>-->
            <!--                            </div>-->
            <!--                          </div>-->
            <!--                        </td>-->
            <!--                        <td>3x</td>-->
            <!--                        <td>$14.99</td>-->
            <!--                        <td>$44.97</td>-->
            <!--                        <td><i class="fas fa-times-circle text-danger"></i></td>-->
            <!--                      </tr>-->
            <!--                      <tr class="mb-3">-->
            <!--                        <td class="d-flex justify-content-start">-->
            <!--                          <img :src="'loyal-img.png'" />-->
            <!--                          <div class="ml-3">-->
            <!--                            <span class="text-orange">MAIN COURSE</span>-->
            <!--                            <p class="font-weight-bold">-->
            <!--                              Chicken curry special with cucumber-->
            <!--                            </p>-->
            <!--                            <div>-->
            <!--                              <i class="fas fa-star text-yellow"></i>-->
            <!--                              <i class="fas fa-star text-yellow"></i>-->
            <!--                              <i class="fas fa-star text-yellow"></i>-->
            <!--                              <i class="fas fa-star"></i>-->
            <!--                              <i class="fas fa-star"></i>-->
            <!--                            </div>-->
            <!--                          </div>-->
            <!--                        </td>-->
            <!--                        <td>3x</td>-->
            <!--                        <td>$14.99</td>-->
            <!--                        <td>$44.97</td>-->
            <!--                        <td><i class="fas fa-times-circle text-danger"></i></td>-->
            <!--                      </tr>-->
            <!--                    </tbody>-->
            <!--                  </table>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="map-wrapper-second-sec">-->
            <!--                <div class="shadow-box">-->
            <!--                  <h4>Customer Favourite</h4>-->
            <!--                  <img-->
            <!--                    :src="'ringProgress (1).png'"-->
            <!--                    alt="ring chart"-->
            <!--                    class="w-100"-->
            <!--                  />-->
            <!--                  <div class="pt-3">-->
            <!--                    <div-->
            <!--                      class="d-flex justify-content-between align-items-center"-->
            <!--                    >-->
            <!--                      <p class="mb-1">Pizza(40%)</p>-->
            <!--                      <p class="mb-1">25</p>-->
            <!--                    </div>-->
            <!--                    <div class="bar-wrapper">-->
            <!--                      <div class="skill-filled"></div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                  <div class="pt-3">-->
            <!--                    <div-->
            <!--                      class="d-flex justify-content-between align-items-center"-->
            <!--                    >-->
            <!--                      <p class="mb-1">Juice (53%)</p>-->
            <!--                      <p class="mb-1">60</p>-->
            <!--                    </div>-->
            <!--                    <div class="bar-wrapper">-->
            <!--                      <div class="skill-filled yellow"></div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                  <div class="pt-3">-->
            <!--                    <div-->
            <!--                      class="d-flex justify-content-between align-items-center"-->
            <!--                    >-->
            <!--                      <p class="mb-1">Dessert (25%)</p>-->
            <!--                      <p class="mb-1">7</p>-->
            <!--                    </div>-->
            <!--                    <div class="bar-wrapper">-->
            <!--                      <div class="skill-filled blue"></div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </section>-->
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// import KTCodePreview from "@/view/content/CodePreview.vue";
// import ApiService from "@/core/services/api.service";
import axios from "axios";
import helpers from "../../helpers";

export default {
  data() {
    return {
      order: {},
      detail: {},
      filledClass: {
        payment: "wizard-box",
        delivery: "wizard-box",
        delivered: "wizard-box",
        review: "wizard-box wizard-last-box"
      },
      orderUUID: "",
      delivery: true,
      map_src:
        'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3402.681080225895!2d74.30689641462978!3d31.477958056385834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391903f78a9e5f5b%3A0x506c3ec6dc4b4302!2sRapidzz%20Solutions!5e0!3m2!1sen!2s!4v1610104332114!5m2!1sen!2s"' +
        ' width="100%" height="300" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"'
    };
  },
  computed: {},
  components: {},
  methods: {
    orderDetail() {
      axios
        .get(this.$path + "/admin/order/details/" + this.orderUUID)
        .then(({ data }) => {
          this.detail = data.data;
          this.detail.is_paid === 1
            ? (this.filledClass.payment = "wizard-box wizard-box-filled")
            : this.filledClass.payment;
          this.detail.status === "Completed"
            ? (this.filledClass.delivery = "wizard-box  wizard-box-filled")
            : this.filledClass.delivery;
          this.detail.status === "Completed"
            ? (this.filledClass.delivered = "wizard-box wizard-box-filled")
            : this.filledClass.delivered;
          this.detail.is_review === 1
            ? (this.filledClass.review =
                "wizard-box wizard-last-box wizard-box-filled")
            : this.filledClass.review;
        })
        .catch(err => {
          this.loader = false;
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    checkImage(event) {
      return (event.target.src = "loyal-img.png");
    }
  },
  mounted() {
    this.order = this.$route.params.orderObject;
    this.orderUUID = this.order.uuid;
    // if(this.order === undefined){
    //   this.orderUUID = this.$route.params.uuid;
    // }else{
    //   this.orderUUID = this.order.uuid;
    // }
    this.orderDetail();
  }
};
</script>

<style scoped>
.item-img {
  width: 60px;
  height: 60px;
}
.shadow-box {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 15px;
}
.wizard-wrapper {
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0;
  line-height: 1;
}
.wizard-inner {
  flex: 0 0 20%;
  max-width: 25%;
  text-align: center;
}
.wizard-box {
  width: 65px;
  height: 65px;
  border: 4px solid #9b9b9b;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.wizard-box:after {
  position: absolute;
  content: "";
  border-bottom: 1px solid #9b9b9b;
  width: 240%;
  left: 60px;
  top: 50%;
  transform: translateY(-50%);
}
.wizard-box-filled {
  background-color: #fd683e;
  border-color: #fd683e;
}
.wizard-last-box.wizard-box:after {
  display: none;
  border-bottom: none;
  width: 0;
}

.wizard-box i {
  font-size: 20px;
}
.wizard-box-filled i {
  color: #fff;
}
.wizard-box-filled.wizard-box:after {
  border-bottom: 1px solid #fd683e;
}

.wizard-inner p {
  font-size: 16px;
  font-weight: 600;
  color: #202020;
}
.wizard-inner span {
  font-weight: normal;
  font-size: 12px;
  color: #9b9b9b;
}

/*  map-wrapper*/
.map-wrapper {
  display: flex;
  justify-content: space-between;
}
.map-wrapper .map-wrapper-first-sec {
  flex: 0 0 calc(75% - 10px);
  max-width: calc(75% - 10px);
}
.map-wrapper .map-wrapper-first-sec iframe {
  width: 100%;
  height: 290px;
  border: none;
}
.map-wrapper .map-wrapper-second-sec {
  flex: 0 0 calc(25% - 10px);
  max-width: calc(25% - 10px);
}
.user-detail-section .btn.custom-btn-style {
  padding: 2px 15px;
}
.user-detail-section img {
  width: 100%;
  border-radius: 5px;
}
.phone-number p {
  display: block;
  width: 34px;
  height: 34px;
  background-color: #624fd1;
  border-radius: 50%;
  line-height: 34px;
  text-align: center;
}
.phone-number li {
  display: flex;
  align-items: center;
}
.phone-number li a {
  color: #000;
}
.phone-number p i {
  color: #fff;
  font-size: 16px;
  display: inline-block;
  line-height: 34px;
}
/*  delivery-guy-wrapper*/
.delivery-guy-wrapper {
  display: flex;
  justify-content: space-between;
}
.delivery-guy-first-section {
  flex: 0 0 calc(75% - 10px);
  width: calc(75% - 10px);
  display: flex;
  justify-content: space-between;
}
.delivery-guy-first-section .phone-number p {
  background-color: #fd683e;
}
.delivery-guy-second-section {
  flex: 0 0 calc(25% - 10px);
  width: calc(25% - 10px);
}
.delivery-guy-second-section img {
  object-fit: contain;
}
.map-wrapper-first-sec .table th,
.map-wrapper-first-sec .table td {
  border: 0;
  box-sizing: border-box;
}

.bar-wrapper {
  width: 100%;
  background-color: #f3f3f3;
  padding: 0;
  height: 10px;
  border-radius: 30px;
}

.bar-wrapper .skill-filled {
  width: 40%;
  background-color: #624fd1;
  height: 10px;
  border-radius: 30px;
}
.bar-wrapper .skill-filled.yellow {
  background-color: #ffa41d;
  width: 53%;
}
.bar-wrapper .skill-filled.blue {
  background-color: #72c1e2;
  width: 25%;
}
.table-img-tr {
  display: flex;
  align-items: center;
  width: 100%;
}

@media only screen and (max-width: 1199.98px) {
  .table-img-tr {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .map-wrapper {
    display: block;
  }
  .map-wrapper .map-wrapper-first-sec {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 0px);
    flex: 0 0 calc(100% - 0px);
    max-width: calc(100% - 0px);
  }
  .map-wrapper .map-wrapper-second-sec {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 0px);
    flex: 0 0 calc(100% - 0px);
    max-width: calc(100% - 0px);
  }
  .table-img-tr {
    display: block;
  }
}
</style>
